import { notification } from 'antd';
import { read } from '@/services/firestore';
import { setRoles } from '../utils/authority';

export default {
  namespace: 'global',

  state: {
    collapsed: false,
    hideMenuForPlaybook: false,
    notices: [],
    noticesLoading: false,
    rbacRoles: [],
    globalCategories: {},
    dataView: 'grid',
    modalData: {},
  },

  effects: {
    *getModalData(_, { call, put }) {
      try {
        const response = yield call(read, {
          module: 'Global',
          params: {
            id: 'modalData',
          },
          stream: false,
        });
        if (response) {
          yield put({
            type: 'save',
            payload: {
              modalData: {
                newReleaseModal: response.newReleaseModal,
                welcomeModal: response.welcomeModal,
              },
            },
          });
        }
      } catch (error) {
        notification.error({
          message: error.message,
        });
      }
    },
    *getRbacRoles(_, { call, put }) {
      try {
        const response = yield call(read, {
          module: 'Global',
          params: {
            id: 'UserRoles',
          },
          stream: false,
        });

        if (response) {
          setRoles(response.roles);
          yield put({
            type: 'save',
            payload: {
              rbacRoles: response.roles,
            },
          });
        }
        // notification.success({
        //   message: 'Organization Deleted',
        // });
      } catch (error) {
        notification.error({
          message: error.message,
        });
      }
    },
    *getGlobalCategories(_, { call, put }) {
      try {
        const response = yield call(read, {
          module: 'Global',
          params: {
            id: 'signatureCategories',
          },
          stream: false,
        });

        if (response) {
          yield put({
            type: 'save',
            payload: {
              globalCategories: response.categories,
            },
          });
        }
        // notification.success({
        //   message: 'Organization Deleted',
        // });
      } catch (error) {
        notification.error({
          message: error.message,
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    changeLayoutCollapsed(state, { payload }) {
      return {
        ...state,
        collapsed: payload,
      };
    },
    hideSiderMenu(state, { payload }) {
      return {
        ...state,
        hideMenuForPlaybook: payload,
      };
    },
    saveDataView(state, { payload }) {
      return {
        ...state,
        dataView: payload,
      };
    },
    saveNotices(state, { payload }) {
      return {
        ...state,
        notices: payload,
      };
    },
    saveClearedNotices(state, { payload }) {
      return {
        ...state,
        notices: state.notices.filter(item => item.type !== payload),
      };
    },
  },

  subscriptions: {
    setup({ history }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      return history.listen(({ pathname, search }) => {
        if (typeof window.ga !== 'undefined') {
          window.ga('send', 'pageview', pathname + search);
        }
      });
    },
  },
};
