import { notification, message } from 'antd';
import { router } from 'umi';
import {
  currentUserInfo,
  updateUserProfile,
  currentUser,
  forceChangePasswords,
  sendEmailVerification,
  updateAlertCount,
  removeUserOrg,
  checkEmailValidation,
  sendResetPasswordEmail,
} from '@/services/user';
import { setAuthority, isSuperAdminByEmail } from '@/utils/authority';
import { reloadAuthorized } from '@/utils/Authorized';
import { list, read } from '@/services/firestore';
import { handleAccessControl } from '@/services/accessControl';
import { AC_STATUS } from '@/pages/Settings/AccessControl';

const namespace = 'user';
export default {
  namespace,

  state: {
    list: [],
    currentUser: {},
    loadingStatus: false,
    authority: 'guest',
    loading: false,
    loadingOperation: false,
    statusOperation: '',
    invite: {},
    emailAlreadyExist: false,
    apiConfig: null,
    isUserVerifying: false,
  },

  effects: {
    *getApiConfig({ payload }, { call, put, takeEvery, take }) {
      try {
        // clear old before new request
        yield put({
          type: `clear:${namespace}/apiConfig`,
        });

        yield put({
          type: 'save',
          payload: { loadingCurrent: true },
        });

        // create subscription
        const service = yield call(read, {
          module: 'private',
          ...payload,
          no404error: true,
        });
        function* push(response) {
          if (response) {
            yield put({
              type: 'save',
              payload: {
                apiConfig: {
                  ...response,
                },
              },
            });
          } else {
            yield put({
              type: 'save',
              payload: {
                apiConfig: {},
              },
            });
          }
        }

        // on every callback from service
        yield takeEvery(service, push);

        // unsubscribe & clear when this action fired
        yield take(`CANCEL_WATCH_${payload.params.id}`);

        service.close();
        yield put({
          type: 'clearCurrent',
        });
      } catch (err) {
        console.log('err: ', err);
      }
    },

    *fetchCurrent(_, { call, put, takeEvery, take }) {
      yield put({
        type: 'saveLoading',
        payload: true,
      });

      // create subscription
      const service = yield call(currentUserInfo);

      function* push(response) {
        if (!response || Object.entries(response).length < 1) {
          yield put({
            type: 'clear',
          });
          return;
        }

        yield put({
          type: 'save',
          payload: {
            currentUser: {
              ...response,
            },
            loading: false,
          },
        });
      }

      // subscribe function to subscription created above
      yield takeEvery(service, push);

      // unsubscribe when asked by creator
      yield take('unsub/user');
      service.close();

      yield put({ type: 'clear' });
    },

    *removeOrganization({ payload }, { call }) {
      if (payload.hasOwnProperty('type') && payload.type === 'delOrg') {
        yield call(removeUserOrg, payload);
      }
    },
    *changePassword({ payload }, { call, put }) {
      yield put({
        type: 'saveLoading',
        payload: true,
      });

      // create subscription
      const res = yield call(forceChangePasswords, payload);

      if (res) {
        message.success('Password Changed Sucessfully');
      }

      yield put({
        type: 'save',
        payload: {
          loading: false,
        },
      });
    },
    *addInviteDescription({ payload }, { put }) {
      try {
        yield put({
          type: 'saveInvite',
          payload: payload,
        });
      } catch (error) {
        console.log(error);
      }
    },
    *forgetPassword({ payload }, { call }) {
      let { email } = payload;
      try {
        yield call(sendResetPasswordEmail, email);
        message.success('Verification link has been sent to your email address.');
      } catch (error) {
        message.error(error.message);
        // yield put({
        //   type: 'changeLoginStatus',
        //   payload: {
        //     status: 'error',
        //     statusMessage: error && error.message && error.message,
        //     currentAuthority: 'guest',
        //     type: 'account',
        //   },
        // });
      }
    },
    *sendVerificationEmail(_, { call }) {
      try {
        yield call(sendEmailVerification);
        // message.success('Verification link has been sent to your email address.');
      } catch (error) {
        message.error(error.message);
        // yield put({
        //   type: 'changeLoginStatus',
        //   payload: {
        //     status: 'error',
        //     statusMessage: error && error.message && error.message,
        //     currentAuthority: 'guest',
        //     type: 'account',
        //   },
        // });
      }
    },
    // set user authority and login status. Triggered from production line setById
    *setAccessControl({ payload }, { put, call }) {
      const user = yield call(currentUser);

      try {
        let currentUserAccessControl = payload.accessControl;
        if (!currentUserAccessControl && !isSuperAdminByEmail(user.email))
          throw new Error('User access data not available');

        yield put({
          type: 'changeAuthority',
          payload: isSuperAdminByEmail(user.email) ? 'superAdmin' : currentUserAccessControl.role,
        });

        reloadAuthorized();
      } catch (error) {
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'login/changeLoginStatus',
          payload: {
            status: 'error',
            statusMessage: error && error.message && error.message,
            currentAuthority: 'guest',
            type: 'account',
          },
        });
      }
    },
    *resetUnreadCount({ payload }, { call }) {
      try {
        yield call(updateAlertCount, payload);
      } catch (error) {
        console.error("Couldn't update unread count", error);
        // notification.error({
        //   message: error.message,
        // });
      }
    },
    // check whether provided email is using by another user or not
    *checkEmail({ payload }, { put, call }) {
      try {
        yield put({
          type: 'clearEmail',
        });
        const response = yield call(checkEmailValidation, payload.email);
        if (response && response.length > 0) {
          message.error('The email address is already in use by another account.');
        } else {
          // if email doesnot exist in firestore then it will put a email update call
          yield put({
            type: 'update',
            payload: {
              type: 'email',
              email: payload.email,
              message: payload.message,
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    //this will update user profile
    *update({ payload }, { put, call }) {
      try {
        yield put({
          type: 'save',
          payload: { loadingOperation: true, statusOperation: '' },
        });
        const response = yield call(updateUserProfile, payload);
        if (payload.type === 'email') {
          if (!payload.message) {
            notification.success({
              message: 'Email updated successfully',
            });
          }
        } else {
          notification.success({
            message: 'Profile updated',
          });
        }
        if (response) {
          yield put({
            type: 'saveEmail',
          });
        }

        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'success' },
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
      }
    },
    *accessControlStatus({ payload }, { call, put, select, take }) {
      yield put({
        type: 'save',
        payload: {
          loadingStatus: payload.status === AC_STATUS.INVITE_ACCEPTED ? 'accepting' : 'declining',
        },
      });

      try {
        const functionParams = {
          orgId: payload.orgId,
          email: payload.email,
          host: window.location.origin,
          status: payload.status,
        };

        const response = yield call(handleAccessControl, functionParams);

        if (response.data.status === 200) {
          notification.success({
            message: response.data.message,
          });
          window.location = window.location.origin;
        }

        // ?if the org is deleted then redirect to home page with message that org is deleted
        if (response.data.status === 500 && response.data.err.code === 5) {
          notification.error({
            message: 'Organization is deleted',
          });
          window.location = window.location.origin;
        }

        yield put({
          type: 'save',
          payload: {
            loadingStatus: false,
          },
        });
      } catch (error) {
        console.log('error :>> ', error);
        //
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: {
            loadingStatus: false,
          },
        });
      }
    },
  },

  reducers: {
    changeAuthority(state, { payload }) {
      setAuthority(payload);
      return {
        ...state,
        authority: payload,
      };
    },
    saveInvite(state, { payload }) {
      return {
        ...state,
        invite: payload,
      };
    },
    saveLoading(state, { payload }) {
      return {
        ...state,
        loading: payload,
      };
    },
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveEmail(state) {
      return {
        ...state,
        emailAlreadyExist: true,
      };
    },

    clear(state) {
      return {
        ...state,
        currentUser: {},
        authority: 'guest',
        loading: false,
      };
    },
    clearEmail(state) {
      return {
        ...state,
        loading: false,
        emailAlreadyExist: false,
      };
    },
    changeNotifyCount(state, action) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          // notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
    setVerifyingState(state, { payload }) {
      return {
        ...state,
        isUserVerifying: payload,
      };
    },
  },
};
