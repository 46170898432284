import React from 'react';
import { routerRedux } from 'dva/router';
import { router } from 'umi';
import { stringify } from 'qs';
import { message, notification, Button } from 'antd';
import { setAuthority } from '@/utils/authority';
import { getPageQuery } from '@/utils/utils';
import { reloadAuthorized } from '@/utils/Authorized';
import { update, read, list } from '@/services/firestore';
import {
  fetchLogin,
  fetchLogout,
  authChange,
  getpasswordb,
  forceChangePasswords,
  getSamlIds,
  authenticateWithSaml,
  checkMarketPlaceStatus,
} from '../services/user';
import defaultSettings from '../defaultSettings';
import { updateAccessControl } from '@/services/organisation';
import { AC_STATUS } from '@/pages/Settings/AccessControl';
import Axios from 'axios';
import { handleAccessControl } from '@/services/accessControl';
import { firestore } from '@/utils/firebase';

export default {
  namespace: 'login',
  state: {
    loading: false,
    status: 'loading',
    statusMessage: '',
    pass: null,
    maintenance: false,
    providerIds: [],
    loaderStatus: false,
    awsMarketPlaceResponse: '',
    count: 0,
    packageLimitations: null,
    whiteLabel: null,
    type: 'account',
    showCloudViewToAllOrgs: false,
    showCloudIQToAllOrgs: false,
    cloudViewEnabledCloudProviders: [],
    xrayEnabledCloudProviders: [],
    _inventoryFilterPresets: [],
    _riskFilterPresets: [],
    _cloudViewPresets: [],
  },

  subscriptions: {
    onAuthStateChanged({ dispatch }) {
      authChange(async user => {
        // get roles list
        dispatch({
          type: 'list',
          payload: user,
        });
        dispatch({
          type: 'globalFilterPresets',
        });

        // this code will only run on new environment
        if (user) {
          dispatch({
            type: 'changeUserRole',
            payload: user.uid,
          });

          dispatch({
            type: 'loginCurrentUser',
            payload: {
              email: user.email,
            },
          });
        } else {
          dispatch({
            type: 'changeLoginStatus',
            payload: {
              status: '',
              statusMessage: '',
              currentAuthority: 'guest',
              type: 'account',
            },
          });
        }
      });
    },
  },

  effects: {
    // ask firebase to login. After this "onAuthStateChanged" subscription is triggered
    *login({ payload }, { call, put }) {
      try {
        const response = yield call(fetchLogin, payload.userName, payload.password);

        let userIschild = yield call(getpasswordb, response.user.uid);

        updateLastActiveTime(response.user);

        if (userIschild === true) {
          yield put({
            type: 'savePassword',
            payload: payload.password,
          });
        }
      } catch (error) {
        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: 'error',
            statusMessage: error && error.message && error.message,
            currentAuthority: 'guest',
            type: 'account',
          },
        });
      }
    },

    changeUserRole({ payload }) {
      changeRole(payload);
    },

    *checkMarketPlaceStatus({ payload }, { call, put }) {
      let orgId = localStorage.getItem('orgId');

      let response = yield call(checkMarketPlaceStatus, { id: payload.id, orgId });

      // response.code = 500;
      if (response) {
        // yield put(routerRedux.replace('/login'));
        yield put({
          type: 'loaderStatus',
          payload: { loaderStatus: true, awsMarketPlaceResponse: response.data.msg },
        });
      }
    },

    *checkVersionInfo(_, { call }) {
      const { cloudnosysVersion } = defaultSettings;
      try {
        const versioningRes = yield call(read, {
          module: 'Global',
          params: {
            id: 'Config',
          },
          stream: false,
        });
        const latestVersion = cloudnosysVersion;
        const { versioning } = versioningRes;
        if (latestVersion !== versioning && versioning !== undefined) {
          openNotification();
        }
      } catch (error) {
        console.error(error);
      }
    },

    *list({ payload }, { call, put, takeEvery }) {
      // create subscription
      const service = yield call(list, {
        module: 'Global',
        params: {
          limit: 1,
        },
      });

      function* push(response) {
        yield put({
          type: 'save',
          payload: {
            cloudViewEnabledCloudProviders:
              response && response.length > 0 ? response[0].cloudViewEnabledCloudProviders : [],
            xrayEnabledCloudProviders:
              response && response.length > 0 ? response[0].xrayEnabledCloudProviders : [],
            showCloudViewToAllOrgs: response[0].showCloudViewToAllOrgs,
            showCloudIQToAllOrgs: response[0].showCloudIQToAllOrgs,
            maintenance: response && response.length > 0 ? response[0].maintenance : false,
            packageLimitations:
              response && response.length > 0 ? response[0].packageLimitations : {},
            whiteLabel:
              response && response.length > 0 && response[0]?.whiteLabel?.isActive
                ? response[0]?.whiteLabel
                : {},
          },
        });

        const user = payload;
        let maintenance = response && response.length > 0 ? response[0].maintenance : false;
        let devTeamEmails =
          response && response.length > 0
            ? response[0]?.emails.filter(email => email.maintenanceModeLogin)
            : [];
        if (maintenance && user) {
          if (!devTeamEmails.find(email => email.email === user.email)) {
            yield put({
              type: 'logout',
            });
          }
        }
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      // yield take(`clear:${namespace}/current`);
      // service.close();
      // yield put({
      //   type: 'clearCurrent',
      // });
    },
    *fetchSamlIds({ payload }, { call, put }) {
      try {
        yield put({
          type: 'setLoading',
          payload: {
            loading: true,
          },
        });
        const response = yield call(getSamlIds, payload.email);
        yield put({
          type: 'saveSamlIds',
          payload: {
            loading: false,
            providerIds: response.data,
          },
        });
      } catch (error) {
        notification.error({
          message: error.message,
          description: error.details,
        });
        yield put({
          type: 'setLoading',
          payload: {
            loading: false,
          },
        });
      }
    },
    // dispatched via subscription after auth
    *loginCurrentUser(_, { put }) {
      // set temporary role until org is selected
      yield put({
        type: 'user/setAccessControl',
        payload: {
          accessControl: {
            role: 'user',
          },
        },
      });

      yield put({
        type: 'redirectAfterLogin',
      });
    },

    *redirectAfterLogin(_, { put }) {
      reloadAuthorized();
      const urlParams = new URL(window.location.href);
      const params = getPageQuery();
      let { redirect, invite, o } = params;

      if (redirect) {
        const redirectUrlParams = new URL(redirect);
        if (redirectUrlParams.origin === urlParams.origin) {
          redirect = redirect.substr(urlParams.origin.length);
          if (redirect.match(/^\/.*#/)) {
            redirect = redirect.substr(redirect.indexOf('#') + 1);
          }
        } else {
          redirect = null;
        }
      } else if (invite && o) {
        // redirect = '/?invite='+invite+'&o='+o;
        redirect = `/invite/${invite}/${o}`;
      }
      let url_string = window.location.href;
      let url = new URL(url_string);
      let subscriptionCustomerId = url.searchParams.get('subscriptionCustomerId');
      let rediretUrl = '/';
      if (subscriptionCustomerId) {
        rediretUrl = `/?subscriptionCustomerId=${subscriptionCustomerId}`;
      }

      yield put(routerRedux.replace(redirect || rediretUrl));
    },

    *authenticateSamlUser(payload, { call, put }) {
      try {
        yield call(authenticateWithSaml, payload);
      } catch (error) {
        yield put({
          type: 'setLoading',
          payload: {
            loading: false,
          },
        });
      }
    },

    *logout(_, { put, call }) {
      yield call(fetchLogout);

      let current = localStorage.getItem('current-version');
      // localStorage.clear();
      // localStorage.removeItem('web-authority');
      localStorage.removeItem('web-authority');
      localStorage.setItem('current-version', current);
      // save version no
      yield put({
        type: 'organisation/clear',
      });
      yield put({
        type: 'organisation/clearList',
      });

      yield put({
        type: 'changeLoginStatus',
        payload: {
          status: false,
          currentAuthority: 'guest',
        },
      });

      reloadAuthorized();

      yield put({
        type: 'cloudAccount/saveSelected',
        payload: [],
      });

      yield put({
        type: 'resourcesReport/clearReport',
      });
      // redirect
      if (window.location.pathname !== '/user/login') {
        yield put(
          routerRedux.replace({
            pathname: '/user/login',
            search: stringify({
              redirect: window.location.ancestorOrigins.origin,
            }),
          })
        );
      }
    },

    *forceChangePassowrd({ payload }, { call, select, put }) {
      yield put({
        type: 'save',
        payload: { loading: true },
      });

      const changePasswordResponse = yield call(forceChangePasswords, payload);

      const currentUserData = yield select(state => state.user.currentUser);
      const { id: orgId } = yield select(state => state.organisation.current);

      if (changePasswordResponse) {
        // get current user data from store
        // ? .update user data
        yield call(update, {
          module: 'user',
          params: {
            id: currentUserData.id,
          },
          data: {
            ChangeAutoGeneratePassword: true,
          },
        });

        const functionParams = {
          orgId,
          email: currentUserData.email,
          host: window.location.origin,
          status: AC_STATUS.INVITE_ACCEPTED,
        };

        yield call(handleAccessControl, functionParams);

        message.success('Password Changed Sucessfully');
        router.push('/');

        yield put({
          type: 'save',
          payload: { loading: false },
        });
      } else {
        message.success('Password Already Updated');
        yield put({
          type: 'save',
          payload: { loading: false },
        });
      }
    },

    *globalFilterPresets({ payload }, { call, put }) {
      try {
        const globalFilterPresets = yield call(read, {
          module: 'Global',
          params: {
            id: 'filterPresets',
          },
          stream: false,
        });

        yield put({
          type: 'save',
          payload: {
            _inventoryFilterPresets: globalFilterPresets._inventoryFilterPresets,
            _riskFilterPresets: globalFilterPresets._riskFilterPresets,
            _cloudViewPresets: globalFilterPresets._cloudViewPresets,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
  },

  reducers: {
    loaderStatus(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    changeLoginStatus(state, { payload }) {
      if (payload.currentAuthority) setAuthority(payload.currentAuthority);
      return {
        ...state,
        status: payload.status,
        statusMessage: payload.statusMessage && payload.statusMessage,
        type: payload.type,
      };
    },
    clearProviderIds(state) {
      return {
        ...state,
        providerIds: [],
      };
    },
    setLoading(state, { payload }) {
      return {
        ...state,
        loading: payload.loading,
      };
    },
    setMaintenance(state, { payload }) {
      return {
        ...state,
        maintenance: payload.maintenance,
      };
    },
    saveSamlIds(state, { payload }) {
      return {
        ...state,
        // loading: payload.loading,
        providerIds: payload.providerIds,
      };
    },

    savePassword(state, { payload }) {
      return {
        ...state,
        pass: payload,
      };
    },
  },
};
async function changeRole(uid) {
  try {
    const accessRes = await read({
      module: 'accessControl',
      stream: false,
      params: {
        orgId: localStorage.getItem('orgId'),
        id: uid,
      },
    });

    const orgRes = await read({
      module: 'organisation',
      stream: false,
      params: {
        id: localStorage.getItem('orgId'),
      },
    });

    let orgOwner = orgRes.owner;
    let myRole = accessRes.role;
    let newRole = 'Owner';

    if (myRole === 'admin') {
      // change it to Administrator or Owner
      // amIOwner
      if (uid === orgOwner) {
        // change it to Owner
        myRole = 'Owner';
      } else {
        myRole = 'Administrator';
        // change it to Adminsitrator
      }

      // update role in access control db
      await update({
        module: 'accessControl',
        params: {
          orgId: localStorage.getItem('orgId'),
          id: uid,
        },
        data: {
          role: newRole,
        },
      });
    }
  } catch (error) {
    console.log(error);
  }
}

let tempCount = 0;
function openNotification() {
  if (tempCount === 0) {
    const key = `open${Date.now()}`;
    const btn = (
      <div
        style={{
          display: 'flex',
          gap: '8px',
        }}
      >
        <Button
          style={{
            padding: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          size="small"
          // type="primary"
          onClick={() => window.open('https://cloudnosys.com/docs/release-notes/', '_blank')}
        >
          View Release Notes
        </Button>
        {/* <Button
          style={{
            padding: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          type="primary"
          size="small"
          onClick={() => location.reload(true)}
        >
          Update Cloudnosys
        </Button> */}
      </div>
    );
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const descriptionText = `Press ${
      isMac ? 'CMD+SHIFT+R' : 'CTRL+SHIFT+R'
    } to get the latest version of Cloudnosys.`;

    notification.open({
      message: 'New Version Released!',
      description: descriptionText,
      duration: 0,
      btn,
      key,
      onClose: close,
    });
  }
  tempCount = tempCount + 1;
}
async function updateLastActiveTime(user) {
  try {
    if (!user || !user.uid) {
      console.error('currentUser or currentUser.uid is undefined.');
      return;
    }

    const currentTime = new Date().getTime();
    const userRef = firestore.collection('Users').doc(user.uid);

    await userRef.update({
      lastActive: currentTime,
      sessionExpired: false,
    });
  } catch (error) {
    console.error('Error updating last active time: ', error);
  }
}
