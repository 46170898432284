import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
          LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        path: '/user',
        redirect: '/user/login',
        exact: true,
      },
      {
        path: '/user/login',
        name: 'login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Login" */ '../User/Login'),
              LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Login').default,
        exact: true,
      },
      {
        path: '/user/change-password',
        name: 'login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__ChangePassword" */ '../User/ChangePassword'),
              LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                .default,
            })
          : require('../User/ChangePassword').default,
        exact: true,
      },
      {
        path: '/user/devlogin',
        name: 'Devlogin',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Devlogin" */ '../User/Devlogin'),
              LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Devlogin').default,
        exact: true,
      },
      {
        path: '/user/forgot-password',
        name: 'forgetpassword',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__ForgotPassword" */ '../User/ForgotPassword'),
              LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                .default,
            })
          : require('../User/ForgotPassword').default,
        exact: true,
      },
      {
        path: '/user/saml-login',
        name: 'Login with SAML',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__SamlLogin" */ '../User/SamlLogin'),
              LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                .default,
            })
          : require('../User/SamlLogin').default,
        exact: true,
      },
      {
        path: '/user/confirmation/:uid',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Confirmation" */ '../User/Confirmation'),
              LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Confirmation').default,
        exact: true,
      },
      {
        path: '/user/dev-register',
        name: 'register',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Register" */ '../User/Register'),
              LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Register').default,
        exact: true,
      },
      {
        path: '/user/register-result',
        name: 'register.result',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__RegisterResult" */ '../User/RegisterResult'),
              LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                .default,
            })
          : require('../User/RegisterResult').default,
        exact: true,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
          LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    Routes: [require('../Authorized').default],
    routes: [
      {
        name: 'invite',
        path:
          '/invite/:uid/:orgId/:createdAt/:inviter/:orgName/:encryptEmail/:inviterId',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Invite" */ '../Invite'),
              LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                .default,
            })
          : require('../Invite').default,
        hideInMenu: true,
        exact: true,
      },
      {
        path: '/',
        redirect: '/monitoring/security-dashboard',
        authority: ['Owner', 'Administrator', 'Auditor', 'DevSecOps', 'user'],
        exact: true,
      },
      {
        path: '/security-dashboard',
        redirect: '/monitoring/security-dashboard',
        authority: ['Owner', 'Administrator', 'Auditor', 'DevSecOps', 'user'],
        exact: true,
      },
      {
        path: '/monitoring',
        redirect: '/monitoring/security-dashboard',
        authority: ['Owner', 'Administrator', 'Auditor', 'DevSecOps', 'user'],
        exact: true,
      },
      {
        path: '/monitoring',
        authority: ['Owner', 'Administrator', 'Auditor', 'DevSecOps', 'user'],
        name: 'Monitoring',
        icon: 'dashboard',
        routes: [
          {
            path: '/monitoring/security-dashboard',
            name: 'Security Dashboard',
            icon: 'dashboard',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Monitoring__SecurityDashboard" */ '../Monitoring/SecurityDashboard'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Monitoring/SecurityDashboard').default,
            authority: [
              'Owner',
              'Administrator',
              'Auditor',
              'DevSecOps',
              'user',
            ],
            exact: true,
          },
          {
            path: '/monitoring/cloud-view',
            name: 'Cloud View',
            icon: 'eye',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Monitoring__CloudViz" */ '../Monitoring/CloudViz'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Monitoring/CloudViz').default,
            authority: [
              'Owner',
              'Administrator',
              'Auditor',
              'DevSecOps',
              'user',
            ],
            exact: true,
          },
          {
            path: '/monitoring/compliance-dashboard',
            name: 'Compliance Dashboard',
            icon: 'bar-chart',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Monitoring__ComplianceDashboard" */ '../Monitoring/ComplianceDashboard'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Monitoring/ComplianceDashboard').default,
            authority: [
              'Owner',
              'Administrator',
              'Auditor',
              'DevSecOps',
              'user',
            ],
            exact: true,
          },
          {
            path: '/monitoring/risks',
            name: 'Risks',
            icon: 'warning',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Monitoring__Risks" */ '../Monitoring/Risks'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Monitoring/Risks').default,
            authority: [
              'Owner',
              'Administrator',
              'Auditor',
              'DevSecOps',
              'user',
            ],
            exact: true,
          },
          {
            path: '/monitoring/risks/custom-signatures/add',
            name: 'Add CustomSignature',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Monitoring__Risks__custom-signatures__Single" */ '../Monitoring/Risks/custom-signatures/Single.js'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Monitoring/Risks/custom-signatures/Single.js')
                  .default,
            authority: [
              'Owner',
              'Administrator',
              'Auditor',
              'DevSecOps',
              'user',
            ],
            exact: true,
          },
          {
            path: '/monitoring/risks/custom-signatures/edit',
            name: 'Edit CustomSignature',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Monitoring__Risks__custom-signatures__Single" */ '../Monitoring/Risks/custom-signatures/Single.js'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Monitoring/Risks/custom-signatures/Single.js')
                  .default,
            authority: [
              'Owner',
              'Administrator',
              'Auditor',
              'DevSecOps',
              'user',
            ],
            exact: true,
          },
          {
            path: '/monitoring/inventory',
            name: 'Inventory',
            icon: 'database',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Monitoring__Inventory__models__Inventory.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/Monitoring/Inventory/models/Inventory.js').then(
                      m => {
                        return { namespace: 'Inventory', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Monitoring__Inventory" */ '../Monitoring/Inventory'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Monitoring/Inventory').default,
            authority: [
              'Owner',
              'Administrator',
              'Auditor',
              'DevSecOps',
              'user',
            ],
            exact: true,
          },
        ],
      },
      {
        name: 'Security & Compliance',
        icon: 'safety-certificate',
        authority: ['Owner', 'Administrator', 'Auditor', 'DevSecOps', 'user'],
        path: '/regulations',
        routes: [
          {
            path: '/regulations',
            redirect: '/regulations/compliance',
            exact: true,
          },
          {
            path: '/regulations/compliance',
            name: 'Standards',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Regulations__Compliance" */ '../Regulations/Compliance'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Regulations/Compliance').default,
            exact: true,
          },
          {
            path: '/regulations/custom-regulations',
            name: 'Custom Regulations',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Regulations__CustomRegulations" */ '../Regulations/CustomRegulations'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Regulations/CustomRegulations').default,
            exact: true,
          },
          {
            path: '/regulations/custom-regulations/add',
            name: 'Add',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Regulations__CustomRegulations__AddCustomRegulation" */ '../Regulations/CustomRegulations/AddCustomRegulation.js'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Regulations/CustomRegulations/AddCustomRegulation.js')
                  .default,
            authority: [
              'Owner',
              'Administrator',
              'Auditor',
              'DevSecOps',
              'user',
            ],
            exact: true,
          },
          {
            path: '/regulations/custom-regulations/edit/:reportId',
            name: 'Edit',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Regulations__CustomRegulations__AddCustomRegulation" */ '../Regulations/CustomRegulations/AddCustomRegulation.js'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Regulations/CustomRegulations/AddCustomRegulation.js')
                  .default,
            authority: [
              'Owner',
              'Administrator',
              'Auditor',
              'DevSecOps',
              'user',
            ],
            exact: true,
          },
          {
            path: '/regulations/custom-regulation/view/:reportId',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Regulations__ReportView" */ '../Regulations/ReportView'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Regulations/ReportView').default,
            exact: true,
          },
          {
            path: '/regulations/compliance/:reportId',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Regulations__ReportView" */ '../Regulations/ReportView'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Regulations/ReportView').default,
            exact: true,
          },
          {
            path: '/regulations/security/:reportId',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Regulations__ReportView" */ '../Regulations/ReportView'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Regulations/ReportView').default,
            exact: true,
          },
        ],
      },
      {
        path: '/playbooks',
        name: 'Playbooks',
        icon: 'branches',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Playbooks__index" */ '../Playbooks/index.js'),
              LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                .default,
            })
          : require('../Playbooks/index.js').default,
        authority: ['Owner', 'Administrator', 'Auditor', 'DevSecOps', 'user'],
        exact: true,
      },
      {
        path: '/playbooks/add',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Playbooks__add__index" */ '../Playbooks/add/index.js'),
              LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                .default,
            })
          : require('../Playbooks/add/index.js').default,
        hideInMenu: true,
        authority: ['Owner', 'Administrator', 'Auditor', 'DevSecOps', 'user'],
        exact: true,
      },
      {
        path: '/playbooks/editor/',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Playbooks__editor__index" */ '../Playbooks/editor/index.js'),
              LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                .default,
            })
          : require('../Playbooks/editor/index.js').default,
        hideInMenu: true,
        authority: ['Owner', 'Administrator', 'Auditor', 'DevSecOps', 'user'],
        exact: true,
      },
      {
        path: '/playbooks/editor/:playbookId',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Playbooks__editor__index" */ '../Playbooks/editor/index.js'),
              LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                .default,
            })
          : require('../Playbooks/editor/index.js').default,
        hideInMenu: true,
        authority: ['Owner', 'Administrator', 'Auditor', 'DevSecOps', 'user'],
        exact: true,
      },
      {
        name: 'account-settings',
        path: '/account',
        hideInMenu: true,
        routes: [
          {
            path: '/account/settings',
            name: 'settings',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Account__index" */ '../Account/index.js'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Account/index.js').default,
            routes: [
              {
                path: '/account/settings',
                redirect: '/account/settings/basic',
                authority: [
                  'Owner',
                  'Administrator',
                  'Auditor',
                  'DevSecOps',
                  'user',
                ],
                exact: true,
              },
              {
                path: '/account/settings/basic',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "p__Account__index" */ '../Account/Settings/Basic'),
                      LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Settings/Basic').default,
                authority: [
                  'Owner',
                  'Administrator',
                  'Auditor',
                  'DevSecOps',
                  'user',
                ],
                exact: true,
              },
              {
                path: '/account/settings/security',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "p__Account__index" */ '../Account/Settings/Security'),
                      LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Settings/Security').default,
                authority: [
                  'Owner',
                  'Administrator',
                  'Auditor',
                  'DevSecOps',
                  'user',
                ],
                exact: true,
              },
              {
                path: '/account/settings/api-config',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "p__Account__index" */ '../Account/Settings/ApiConfig'),
                      LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Settings/ApiConfig').default,
                authority: [
                  'Owner',
                  'Administrator',
                  'Auditor',
                  'DevSecOps',
                  'user',
                ],
                exact: true,
              },
            ],
          },
        ],
      },
      {
        path: '/setup',
        name: 'Setup',
        icon: 'dashboard',
        routes: [
          {
            path: '/setup',
            redirect: '/setup/cloud-accounts',
            exact: true,
          },
          {
            path: '/setup/cloud-accounts',
            name: 'Cloud Accounts',
            icon: 'cloud',
            routes: [
              {
                path: '/setup/cloud-accounts',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CloudAccounts/CloudAccountsList'),
                      LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CloudAccounts/CloudAccountsList').default,
                exact: true,
              },
              {
                path: '/setup/cloud-accounts/add',
                name: 'Add Cloud Account',
                hideInMenu: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CloudAccounts/AddCloudAccount'),
                      LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CloudAccounts/AddCloudAccount').default,
                authority: [
                  'Owner',
                  'Administrator',
                  'Auditor',
                  'DevSecOps',
                  'user',
                ],
                exact: true,
              },
              {
                path: '/setup/cloud-accounts/edit',
                name: 'Edit Cloud Account',
                hideInMenu: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CloudAccounts/EditCloudAccountV2'),
                      LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CloudAccounts/EditCloudAccountV2').default,
                authority: [
                  'Owner',
                  'Administrator',
                  'Auditor',
                  'DevSecOps',
                  'user',
                ],
                exact: true,
              },
              {
                path: '/setup/cloud-accounts/real-time-alerts',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CloudAccounts/RealTimeAlerts'),
                      LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CloudAccounts/RealTimeAlerts').default,
                exact: true,
              },
            ],
          },
          {
            name: 'Settings ',
            icon: 'setting',
            authority: [
              'Owner',
              'Administrator',
              'Auditor',
              'DevSecOps',
              'user',
            ],
            path: '/setup/settings',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Settings__models__accessInvites.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/Settings/models/accessInvites.js').then(
                      m => {
                        return { namespace: 'accessInvites', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Settings__index" */ '../Settings/index.js'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Settings/index.js').default,
            routes: [
              {
                path: '/setup/settings',
                redirect: '/setup/settings/integration',
                exact: true,
              },
              {
                path: '/setup/settings/integration',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Settings__models__accessInvites.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/Settings/models/accessInvites.js').then(
                          m => {
                            return { namespace: 'accessInvites', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "p__Settings__index" */ '../Settings/Integration/index.js'),
                      LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Settings/Integration/index.js').default,
                exact: true,
              },
              {
                path: '/setup/settings/integration/authorize',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Settings__models__accessInvites.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/Settings/models/accessInvites.js').then(
                          m => {
                            return { namespace: 'accessInvites', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "p__Settings__index" */ '../Settings/Integration/authorize.js'),
                      LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Settings/Integration/authorize.js').default,
                exact: true,
              },
              {
                path: '/setup/settings/access-control',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Settings__models__accessInvites.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/Settings/models/accessInvites.js').then(
                          m => {
                            return { namespace: 'accessInvites', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "p__Settings__index" */ '../Settings/AccessControl'),
                      LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Settings/AccessControl').default,
                exact: true,
              },
              {
                path: '/setup/settings/suppression-rules',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Settings__models__accessInvites.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/Settings/models/accessInvites.js').then(
                          m => {
                            return { namespace: 'accessInvites', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "p__Settings__index" */ '../Settings/SuppressionRules'),
                      LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Settings/SuppressionRules').default,
                exact: true,
              },
              {
                path: '/setup/settings/roles',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Settings__models__accessInvites.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/Settings/models/accessInvites.js').then(
                          m => {
                            return { namespace: 'accessInvites', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "p__Settings__index" */ '../Settings/Roles'),
                      LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Settings/Roles').default,
                exact: true,
              },
              {
                path: '/setup/settings/billing',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Settings__models__accessInvites.js' */ '/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/pages/Settings/models/accessInvites.js').then(
                          m => {
                            return { namespace: 'accessInvites', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "p__Settings__index" */ '../Settings/Billing'),
                      LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Settings/Billing').default,
                exact: true,
              },
            ],
          },
        ],
      },
      {
        path: 'https://ai.cloudnosys.com/',
        name: 'CloudIQ',
        icon: 'robot',
        target: '_blank',
        tag: {
          name: 'Preview',
          color: 'blue',
        },
        exact: true,
      },
      {
        name: 'exception',
        icon: 'warning',
        path: '/exception',
        hideInMenu: true,
        routes: [
          {
            path: '/exception/403',
            name: 'not-permission',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__403" */ '../Exception/403'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/403').default,
            exact: true,
          },
          {
            path: '/exception/404',
            name: 'not-find',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__404" */ '../Exception/404'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/404').default,
            exact: true,
          },
          {
            path: '/exception/500',
            name: 'server-error',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__500" */ '../Exception/500'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/500').default,
            exact: true,
          },
          {
            path: '/exception/trigger',
            name: 'trigger',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__Trigger__index" */ '../Exception/Trigger/index.js'),
                  LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/Trigger/index.js').default,
            exact: true,
          },
        ],
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('/home/hammad/Desktop/Ahmeds/cloudnosys-v3/frontend-v3/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
